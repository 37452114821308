<app-hero-image imgsrc="https://thedealiomarketing.com/wp-content/uploads/sites/2/2020/08/transparent-girl.png" containimg="true" darkbg="true" objectposition="top right">
	<h1>How It Works.</h1>
	<div class="subtitle" style="margin-bottom: 0;">Use the natural energy of your own body to eliminate pain.</div>
	<p style="opacity: .7; margin-bottom: 30px;">This reusable pain relief patch attaches directly at the source of pain, and spots the signals from reaching you. </p>
	<a href="#" (click)="openModalVideo($event)"  class="play-button"><img style="width: 300px; height: 200px; object-fit: cover;" class="rounded-image" src="https://thedealiomarketing.com/wp-content/uploads/sites/2/2020/09/how-signal-works-thumbnail.jpg"></a>
	
</app-hero-image>



<section class="container padded-row featured-text">
		<p class="subtitle">Stopping unneccessary pain signals at the source allow you to perform at your peak.</p>

</section>

<section class="emphasized-row padded-row">
	<div class="container">
		<div class="col-6">
			<h2>Fast Acting, Drug-Free Pain Relief</h2>
			<p>Signal utilizes patented “Neuro Capacitive Coupling” technology in a thin, reusable patch that can turn down pain. It’s 100% safe and is fast acting.</p>
			<p>Signal is a revolutionary Drug-Free, Chemical free, completely safe way to relieve pain in minutes without batteries, wires, herbs, or drugs of any	kind! It works with any type of pain.</p>

		
			<ul class="icon-list">
				<li class="active">
					<div class="icon">
						1
					</div>
					<div class="list-content">
						
						<p>Revolutionary material utilizing “Neuro Capacitive Coupling technology to turn down pain signals.</p>
					</div>
				</li>
				<li>
					<div class="icon">
						2
					</div>
					<div class="list-content">
						
						<p>Antenna like material that draws in your body’s electrical pain signals.</p>
					</div>
				</li>
				<li>
					<div class="icon">
						3
					</div>
					<div class="list-content">
						
						<p>Comfortable and flexible top to bottom to allow it to be placed right where the pain originates.</p>
					</div>
				</li>
			</ul>
		</div>
		<div class="col-6 vertical-align" style=" padding: 15px; justify-content: center;">
			<img src="https://thedealiomarketing.com/wp-content/uploads/sites/2/2020/08/3d-render-explosion-signal-relief.jpg" style="border-radius: 20px; mix-blend-mode: multiply;" class="responsive-img">
		</div>

	</div>
</section>


<section class="container padded-row">

	<div class="col-6">
		<h2>Immediate Pain Relief</h2>
		<p>Unlike pills, Signal Relief works instantly to provide pain relief. as you place Signal Relief over your pain points, You’ll notice an immediate reduction in pain.</p>
	</div>
</section>


<app-footercta></app-footercta>

<!-- Modal -->
<app-modal id='judist-priest' class="modal-container">
	<iframe loading="lazy" style="width: 60vw; height: 40vw;"  src="https://www.youtube.com/embed/qRWxGvdWSE0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</app-modal>