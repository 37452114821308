<section class=" padded-row dark-row">
	<div class="container">
		<div class="col-7">
			<h2>Be Pain Free, Risk Free</h2>
			<p class="subtitle">30-day money back guarantee and 1 year warranty on all purchases</p>
		</div>
		<div class="col-5">
			<div class="product-box">
				<img src="https://s3.amazonaws.com/DealioClients/Caliber/signal-relief-icon-blue.png" class="responsive-img" style="max-width: 200px;">
				<br />
				<a href="/cart" class="reverse-cta-button" >Add to Cart</a>
			</div>
		</div>
	</div>
</section>