<app-hero-image imgsrc="https://thedealiomarketing.com/wp-content/uploads/sites/2/2020/09/SignalStillsProofs-37.jpg" darkbg="false">
	<h1>Shop</h1>
	<div class="subtitle">Get the patch that fits for you and eliminate pain.</div>
</app-hero-image>

<section class="featured-text">
<h2>Welcome to the Signal Relief Shop</h2>
<p class="subtitle">Make the decision to live with less pain</p>
</section>

<app-producttiles></app-producttiles>