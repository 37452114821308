import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  displayMobileMenu: boolean;
  scrolled: boolean;

  constructor() { }

  ngOnInit(): void {
    window.addEventListener('scroll', this.scrollHandler, true);
  }


  toggleMobile(): void {
    this.displayMobileMenu = !this.displayMobileMenu;
  }

  scrollHandler(e: Event): void {
    if (window.scrollY > 100) {
      document.getElementById('primary-header').classList.add('minified');
    } else if (window.scrollY < 50) {
      document.getElementById('primary-header').classList.remove('minified');
    }
  }

}
