import { Component, OnInit, Input } from '@angular/core';
import { ModalService } from '../../../services/modal.service';
import { templateJitUrl } from '@angular/compiler';

@Component({
  selector: 'app-hero-image',
  templateUrl: './hero-image.component.html',
  styleUrls: ['./hero-image.component.scss']
})
export class HeroImageComponent implements OnInit {

  @Input() imgsrc: string;
  @Input() containimg = false;
  @Input() darkbg = false;
  @Input() objectposition = "center center";
  @Input() vidsrc: string;

  constructor(private modalService: ModalService) { }

  ngOnInit(): void {
  }

  openModalVideo(e): void {
    e.preventDefault();
    this.modalService.toggle();
  }

}
