import { Injectable } from '@angular/core';

import * as products from './products.json';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  products: any = (products as any).default;
  constructor() { }

  public get items(): string {
    return this.products;
  }
}
