<app-pageheader>
    <h1>Account</h1>
    <p class="subtitle">Welcome Back %Name%
</app-pageheader>

<section>
<div class="container">
    <div class="col-4 ">
        <div class="sidebar">
            <h3>Your Account</h3>
            <ul>
                <li>Shopping Cart</li>
                <li>My Orders</li>
                <li class="active-page">Contact Information</li>
                <li>Shipping</li>
                <li>Customer Support</li>
            </ul>
        </div>
    </div>

    <div class="col-8">

        <div class="sub-section">
            <h2>Contact Information</h2>
            <div class="form-group"><label for="name">Name</label>
                <input id="name" type="text"></div>
            
            <div class="form-group"><label for="name">Email</label>
                <input id="email" type="text"></div>
            
        </div>
        <div class="sub-section">
            <h2>Shipping Information</h2>
            <div class="form-group"><label for="name">Street Address</label>
                <input id="name" type="text"></div>
            
            <div class="form-group"><label for="name">Street Address 2</label>
                <input id="email" type="text"></div>
            
            <div class="form-group"><label for="name">City</label>
                <input id="email" type="text">
            
            <label for="name">State</label>
                <input id="email" type="text"></div>
            
        </div>
    </div>
</div>
</section>

