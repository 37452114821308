import { Component, OnInit } from '@angular/core';

import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'app-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.scss']
})
export class HowItWorksComponent implements OnInit {

  modalService: ModalService;

  constructor(modalService: ModalService) {
    this.modalService = modalService;
   }

  ngOnInit(): void {
  }
  openModalVideo (e):void {
    e.preventDefault();
    this.modalService.toggle();
  }
}
