import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './components/home/home.component';
import { AccountComponent } from './components/pages/account/account.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { TestimonialsComponent } from './components/pages/testimonials/testimonials.component';
import { HowItWorksComponent } from './components/pages/how-it-works/how-it-works.component';
import { ShopComponent } from './components/pages/shop/shop.component';
import { SupportComponent } from './components/pages/support/support.component';

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'account', component: AccountComponent},
  {path: 'faq', component: FaqComponent},
  {path: 'testimonials', component: TestimonialsComponent},
  {path: 'how-it-works', component: HowItWorksComponent},
  {path: 'shop', component: ShopComponent},
  {path: 'support', component: SupportComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
