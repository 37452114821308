import { Component, OnInit } from '@angular/core';
import { ReviewsService } from '../../../services/reviews.service';
@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss']
})
export class ReviewsComponent implements OnInit {

  reviews: any;
  constructor(private reviewsService: ReviewsService) {
    this.reviews = reviewsService.allReviews;
  }

  ngOnInit(): void {
  }

}
