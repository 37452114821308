<footer id="primary-footer">

	<nav class="footer-nav">
		<div class="footer-logo">
			<a href="/"><img src="https://s3.amazonaws.com/DealioClients/Caliber/signal-relief-logo-white.png"></a>
		</div>
		<div class="">
			<h3>Navigation</h3>
			<ul>
				<li><a href="/shop"  routerLinkActive="active">The Patch</a></li>
				<li><a href="/how-it-works"  routerLinkActive="active">How it works</a></li>
				<li><a href="/testimonials" routerLinkActive="active">Testimonials</a></li>
				<li><a href="/faq" routerLinkActive="active">FAQs</a></li>
				<li><a href="/account" routerLinkActive="active">Account</a></li>
			</ul>
		</div>
		<div class="">
			<h3>Company</h3>
			<ul>
				<li><a href="/how-it-works">About us</a></li>
				<li><a href="/contact">Contact Us</a></li>
				<li><a href="/support">Support</a></li>
				<!-- <li><a href="#">Careers</a></li> -->
			</ul>
		</div>
		<div class="">
			<h3>Accessories</h3>
			<ul>
				<li><a href="/shop">Adhesives</a></li>
				<li><a href="/account">Order Status</a></li>
				<li><a href="#">Refer a Friend</a></li>
			</ul>
		</div>
		<div class="newsletter">
			<h3>Newsletter</h3>
			<form>
				<label for="newsletter-email"></label>
				<input type="text" placeholder="Email Address&hellip;" name="newsletter-email">
			</form>
		</div>
	</nav>

	<div class="legal">
		<div class="copyright">
			Copyright &copy; 2020 Calibermed |&nbsp;<a href="#">Privacy Policy</a>&nbsp;|&nbsp;<a href="#">Terms of Use</a>
		</div>
		<div class="footer-social">
			<ul>
				<li><a href="https://www.facebook.com/signalrelief/">Facebook</a></li>
				<li><a href="https://www.instagram.com/signalrelief/">Instagram</a></li>
			</ul>
		</div>
	</div>

</footer>
