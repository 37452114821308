<header #primaryHeader id="primary-header">
	<nav class="mobile-menu">
		<div class="mobile-hamburger" (click)="toggleMobile()" [ngClass]="{'menu-open':displayMobileMenu}"><span>Toggle Menu</span></div>
		
		<div class="mobile-menu-items"  >
			<ul>
				<li><a href="/how-it-works">How It Works</a></li>
				<li><a href="/testimonials">Testimonials</a></li>
				<li><a href="/shop">Shop</a></li> 
				<li><a href="/faq">FAQs</a></li> 
				<li><a href="/support">How To Use</a></li>
				<li><a href="/account">Account</a></li>
			</ul>
		</div>
		<div class="logo" >
			<a href="/">
				<img src="https://s3.amazonaws.com/DealioClients/Caliber/signal-relief-logo-blue.png" class="full-logo">
				<img src="https://thedealiomarketing.com/wp-content/uploads/sites/2/2020/09/signal-relief-logo-icon.png" class="min-logo">
			</a>
		</div>
		<a class="cta-button">Buy Now</a>

	</nav>

	<nav class="desktop-menu">
		<ul class="top-left">
			<li><a href="/how-it-works">How It Works</a></li>
			<li><a href="/testimonials">Testimonials</a></li>
			<li><a href="/shop">Shop</a></li> 
		</ul>
		
		<div class="logo" >
			<a href="/" >
				<img src="https://s3.amazonaws.com/DealioClients/Caliber/signal-relief-logo-blue.png" class="full-logo">
				<img src="https://thedealiomarketing.com/wp-content/uploads/sites/2/2020/09/signal-relief-logo-icon.png" class="min-logo">
			</a>
		</div>

		<ul class="top-right">
			<li><a href="/faq">FAQs</a></li> 
			<li><a href="/support">How To Use</a></li>
			<li><a href="/account">Account</a></li>
		</ul>

		<a class="cta-button" href="#">Buy Now</a>
	</nav>
</header>