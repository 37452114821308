import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalService } from '../../../services/modal.service';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ModalComponent implements OnInit {
    private visibility: boolean;

    constructor(private modalService: ModalService) {
        this.modalService.modalActive$
            .subscribe(visibility => this.updateModal(visibility));
    }

    ngOnInit(): void {
    }


    toggleModal(e): void {
        console.log(e);
        e.preventDefault();
        this.modalService.toggle();
    }
    updateModal(visible: boolean): void {
        this.visibility = visible;
        if (visible) {
            document.body.classList.add('modal-active');
        } else {
            document.body.classList.remove('modal-active');
        }
    }
}
