import { Injectable } from '@angular/core';

import * as reviews from './reviews.json';

@Injectable({
  providedIn: 'root'
})
export class ReviewsService {
  reviews: any = (reviews as any).default;
  constructor() { }

  public get allReviews(): string {
    return this.reviews;
  }
}
