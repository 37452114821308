

<ul class="product-tiles">
<li *ngFor="let product of products">

    <img src="{{product.img}}">
    <h3>{{product.name}}</h3>
    <p style="margin-top: 0;" class="price"><strong>${{product.price}}</strong></p>
    <a href="#" class="cta-button reverse-cta-button">Add to Cart</a>

</li>
</ul>