import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private modalActive: boolean;
  modalActive$: Subject<boolean> = new Subject<boolean>();
  constructor() {
    this.modalActive$.subscribe((value) => {
      this.modalActive = value;
    });
  }

  get visible(): boolean {
    return this.modalActive;
  }
  toggle(): void {
    this.modalActive$.next(!this.modalActive);
  }
}
