<ul class="tile-blocks">
	<li *ngFor="let testimonial of testimonials | slice:0:limit" >
		<img src="{{ testimonial.img }}" class="responsive-img">
		<div class="tile-content">
			<div class="tile-name">
				<h5>{{ testimonial.name }}</h5>
				<p><em>{{testimonial.excerpt }}</em></p>
			</div>
			
			<div class="tile-reveal">
				{{ testimonial.shortreview }}
			</div>
		</div>
	</li>
</ul>