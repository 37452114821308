<section class="hero" [ngClass]="{'reversedhero': darkbg, 'video-bg': vidsrc}">
	
	<video *ngIf="vidsrc" muted autoplay loop>
		<source src="{{ vidsrc }}" type="video/mp4">
	</video>

	<img *ngIf="imgsrc" src="{{ imgsrc }}" [ngClass]="{'contained-hero': containimg}" [ngStyle]="{'objectPosition': objectposition}">
		<div class="container">
			<div class="hero-content">
				<ng-content ></ng-content>
			</div>
		</div>	
</section>





