import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  observerOptions: object = {
    root: null,
    rootMargin: '0px',
    threshold: .2
  };
  sections: any;
  observer: any;

  constructor(private modalService: ModalService) { }

  ngOnInit(): void {

    // Adding class when object comes into screen
    this.observer = new IntersectionObserver(this.intersectionHandler, this.observerOptions);
    this.sections = document.querySelectorAll('section');
    this.sections.forEach(section => {
      this.observer.observe(section);
    });
  }
  openModalVideo(e): void {
    e.preventDefault();
    this.modalService.toggle();
  }
  intersectionHandler(e): void {
    e.forEach(entry => {
      if (!entry.isIntersecting) {
        return;
      }
      entry.target.classList.add('visible');
      // console.log(entry);
    });
  }


}
