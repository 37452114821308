import { Component, OnInit, Input } from '@angular/core';
import { TestimonialsService } from '../../../services/testimonials.service';

@Component({
  selector: 'app-blog-tiles',
  templateUrl: './blog-tiles.component.html',
  styleUrls: ['./blog-tiles.component.scss']
})
export class BlogTilesComponent implements OnInit {
  @Input() limit: number;
  testimonials: any;

  constructor(private testimonialService: TestimonialsService) {
    this.testimonials = testimonialService.reviews;
    // if (this.limit === undefined) {
    //   this.limit = this.testimonials.length;
    // }
    // console.log(this.limit);
  }

  ngOnInit(): void {
  }

}
