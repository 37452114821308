<div class="primary-content">
<app-hero-image vidsrc="https://s3.amazonaws.com/DealioClients/Caliber/signal-relief-video-compressed.mp4" objectposition="top center" darkbg="true">
	<h1 >Take Your Power Back.</h1>
	<div class="subtitle">Live your life pain-free.</div>
	<a href="#" style="font-size: 17px; padding: 10px 24px;" class="reverse-cta-button" (click)="openModalVideo($event)">▶ Experience Signal Relief</a>
</app-hero-image>

<section class="container featured-text">
	<img src="https://s3.amazonaws.com/DealioClients/Caliber/patches-1.png">

	<p class="subtitle">Use the natural energy of your own body<wbr> to eliminate pain.
	</p>
	<p class="subtitle"><a href="/how-it-works">How it works ›</a></p>

</section>

<section class="emphasized-row padded-row">
	<div class="container">
		<div class="col-6">
			<h2>Fast Acting, Drug-Free Pain Relief</h2>
			<p>Signal Relief is completely non-invasive, it adds zero drugs, zero electricity, zero anything into your body. Most people with chronic or temporary pain receive significant relief within minutes.
			</p>

		
			<ul class="icon-list">
				<li class="active">
					<div class="icon">
						<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="82" height="82" viewBox="0 0 64 64"><defs><style>.cls-1{fill:#0d759e;}</style></defs><path class="cls-1" d="M46.207,49.293l-5-5a1,1,0,0,0-1.414,1.414l5,5a1,1,0,0,0,1.414-1.414Z"/><path class="cls-1" d="M35.5,47a1,1,0,0,0-1,1v7a1,1,0,0,0,2,0V48A1,1,0,0,0,35.5,47Z"/><path class="cls-1" d="M29.793,44.293l-4.5,4.5a1,1,0,1,0,1.414,1.414l4.5-4.5a1,1,0,0,0-1.414-1.414Z"/><path d="M43.1489,25.7769l9.8657-5.92a1,1,0,0,0-1.0292-1.7148l-10,6-6.6338,7.5083L21.5649,21.541l.9273-7.417a1,1,0,0,0-1.9844-.248l-1.0727,8.583,16.2133,11.89Z"/><path d="M53.2944,30.3926a.9993.9993,0,0,0-1.4018-.187L35.6055,42.6606l-19.3814-20.35L6.9858,17.126a1,1,0,1,0-.9716,1.748L14.8809,23.8,35.3945,45.3394l17.7129-13.545A1,1,0,0,0,53.2944,30.3926Z"/><path d="M11.9,17.8a1,1,0,1,0,1.2-1.6l-4-3a1,1,0,0,0-1.2,1.6Z"/><path d="M13.7,15.6a1,1,0,0,0,1.6-1.2l-3-4a1,1,0,1,0-1.6,1.2Z"/><path d="M16.53,14.2427a1,1,0,1,0,1.94-.4854l-1-4a1,1,0,1,0-1.94.4854Z"/></svg>
					</div>
					<div class="list-content">
						<h3>Athletes</h3>
						<p>The Signal Relief patch allows athletes to perform at their peak by minimizing their pain or eliminating it completely.</p>
					</div>
				</li>
				<li>
					<div class="icon">
						<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="82" height="82" viewBox="0 0 64 64"><defs><style>.cls-1{fill:#0d759e;}</style></defs><path class="cls-1" d="M16.05,42.1719a.9994.9994,0,0,0-1.3735.3374l-3.6622,6.0488a1,1,0,0,0,1.711,1.0361l3.6621-6.0488A1,1,0,0,0,16.05,42.1719Z"/><path class="cls-1" d="M15.3105,37.9331a1,1,0,0,0-1.21-.7324l-6.7979,1.67a1,1,0,0,0,.2378,1.9712,1.014,1.014,0,0,0,.24-.0288l6.7978-1.67A1,1,0,0,0,15.3105,37.9331Z"/><path class="cls-1" d="M7.1841,30.876l5.4438,3.2959a1,1,0,0,0,1.0362-1.711L8.22,29.165a1,1,0,0,0-1.0361,1.711Z"/><path d="M52.5,21H46.6128l-3,25H22.387l-3-25H9.5a1,1,0,0,0,0,2h8.1128l2.76,23h-.1084L16.5308,60.7549a1,1,0,1,0,1.9384.49L21.8213,48H44.1787l3.3521,13.2451a1,1,0,0,0,1.9384-.49L45.7354,46H45.627l2.76-23H52.5a1,1,0,0,0,0-2Z"/><path d="M33.5,43a1,1,0,0,0,1-1V17a1,1,0,0,0-2,0V42A1,1,0,0,0,33.5,43Z"/><path d="M27.7192,24.3755l-4,5a1,1,0,1,0,1.5616,1.249l4-5a1,1,0,1,0-1.5616-1.249Z"/><path d="M42.5005,31a1,1,0,0,0,.78-1.6245l-4-5a1,1,0,1,0-1.5616,1.249l4,5A.9967.9967,0,0,0,42.5005,31Z"/><path d="M10.499,16a.997.997,0,0,0,.2437-.03l8.0737-2.0215,9.4741-3.1582,2.1582-6.4741a1,1,0,0,0-1.8974-.6328L26.71,9.21l-8.4522,2.82-8,2A1,1,0,0,0,10.499,16Z"/><path d="M54.7749,14.0386l-6.9585-1.9873L39.2905,9.21,37.4487,3.6836a1,1,0,1,0-1.8974.6328L37.71,10.7905l9.5156,3.1709,7,2a1,1,0,1,0,.55-1.9228Z"/></svg>
					</div>
					<div class="list-content">
						<h3>Aging</h3>
						<p>With age can come additional pain. This pain can make it feel like you cannot live your life as you once did and can rob you of the simple things in life.</p>
					</div>
				</li>
				<li>
					<div class="icon">
						<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="82" height="82" viewBox="0 0 64 64"><defs><style>.cls-1{fill:#0d759e;}</style></defs><path d="M39.938,16.4238,33.8271,7.4375a1,1,0,0,0-1.6542,1.125l6.1108,8.9863a4.577,4.577,0,0,1-.3032,5.5332L22.8091,40.8545l5.5063,11.0127,4.2749.9971c-.0024.0449-.0068.0908-.0127.1357H26.46l-5.8047-6.7725-4.7559-2.8828a2.0308,2.0308,0,0,1-.0869-2.7343l3.7715-1.78,1.123-1.1231L31.2744,21.8911,18.3184,8.2124a1,1,0,1,0-1.4522,1.375L28.7256,22.1089,19.2227,36.3628l-.8067.8071-3.7876,1.7876-.123.1231a4.03,4.03,0,0,0,.0932,5.789l4.7461,2.9029L25.54,55h7.5181c.2481,0,1.083-.0942,1.415-1.3076.1768-.645.272-2.0069-.6337-2.543l-4.1548-1.0166-4.4937-8.9873L39.502,24.3809A6.5842,6.5842,0,0,0,39.938,16.4238Z"/><path class="cls-1" d="M44,18a.9967.9967,0,0,0,.707-.293l5-5a1,1,0,0,0-1.414-1.414l-5,5A1,1,0,0,0,44,18Z"/><path class="cls-1" d="M51,21H44a1,1,0,0,0,0,2h7a1,1,0,0,0,0-2Z"/><path class="cls-1" d="M44.707,26.293a1,1,0,0,0-1.414,1.414l4.5,4.5a1,1,0,0,0,1.414-1.414Z"/></svg>
					</div>
					<div class="list-content">
						<h3>Injuries</h3>
						<p>Injuries can cause the body a tremendous amount Of pain and this pain can seem sometimes As if it will never subside. With Signal Relief, you Can experience real pain relief from injuries and Get back to your normal life.</p>
					</div>
				</li>
			</ul>
		</div>
		<div class="col-6 vertical-align" style=" padding: 15px; justify-content: center;">
			<img src="https://s3.amazonaws.com/DealioClients/Caliber/SignalStillsProofs-52+(1).jpg" style="border-radius: 20px;" class="responsive-img">
		</div>

	</div>
</section>

<section class="container padded-row">

	<div class="col-12 featured-text">
		<h2>Less Pain, All Gain</h2>
		<p class="subtitle">Chronic pain significantly impacts quality of life. Quiet the pain signals with Signal Relief.</p>
	</div>

	<div class="col-6" style="text-align:center;">
		<img class="responsive-img" style="max-width: 400px; width: 100%;" src="https://s3.amazonaws.com/DealioClients/Caliber/signal-relief-icon-blue.png">
	</div>

	<div class="col-6">
		<ul class="diagram-list">
			<li><h6>Flexible Shape</h6>
			<p>Designed to contour to the shape of your body and remain there in comfort. Water-proof adhesive patches ensure that Signal Relief remains right at the point to provide the greatest relief.</p></li>
			<li><h6>Super Conductive Material</h6>
			<p>Shimmering on the outside of the patch is the nano material that draws the pain signals from your body.</p></li>

			<li><h6>Precision Signal Refraction</h6>
			<p>A unique design that redirects and points pain signals away from your body.</p></li>
		</ul>
	</div>



</section>

<section  class="featured-row">
	
	<div class="col-6 faded-img-container">
		<img src="https://s3.amazonaws.com/DealioClients/Caliber/SignalStillsProofs-37.jpg" class="faded-img responsive-img">
	</div>
	<div class="col-6" style="padding-top: 120px; padding-bottom: 120px;">
		<h3>A Non-Invasive Return to Health</h3>
		<p>Join the thousands of others who have taken control of their pain with signal relief.</p>	
		<a href="/shop" class="reverse-cta-button">Shop Now</a>
	</div>

</section>

<section  class=" padded-row dark-row">
	
		<div class="col-12">
			<h2 style="text-align:center;">Testimonials</h2>
		</div>
		<app-blog-tiles limit="3"></app-blog-tiles>
</section>


</div>
<app-footercta></app-footercta>

<!-- Modal -->
<app-modal id='judist-priest' class="modal-container">
	<iframe loading="lazy" style="width: 75vw; height: 80vh;" src="https://www.youtube.com/embed/T6U23dpnQcs" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</app-modal>