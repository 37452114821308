import { Component, OnInit } from '@angular/core';

import { ProductsService } from '../../../services/products.service';

@Component({
  selector: 'app-producttiles',
  templateUrl: './producttiles.component.html',
  styleUrls: ['./producttiles.component.scss']
})
export class ProducttilesComponent implements OnInit {

  products: any;
  constructor(private productsService: ProductsService) { 
    this.products = productsService.items;
  }

  ngOnInit(): void {
  }

}
