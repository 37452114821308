import { Injectable } from '@angular/core';

import * as testimonials from './testimonials.json';

@Injectable({
  providedIn: 'root'
})
export class TestimonialsService {
  testimonials: any = (testimonials as any).default;
  constructor() { }

  public get reviews(): string {
    return this.testimonials;
  }
}
