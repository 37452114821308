<app-pageheader>
    <h1 style="text-align: center;">How To Use Signal Relief</h1>
    <p class="subtitle" style="text-align: center;">Become a Signal Sensai by learning how to best approach pain management.</p>
</app-pageheader>



<section>
    <div class="featured-text">
        <h2>Instructional Videos</h2>
        <p class="subtitle">Use the videos below to help you maximize the effectiveness of your Signal Relief Patch</p>
        <p>View more of our videos on our <a href="https://www.youtube.com/channel/UCHSwvvjEzBo3KetoNNJh0nQ">Youtube Channel</a></p>
    </div>
    <div class="container">
    <ul class="support-tiles">
        <li><iframe src="https://www.youtube.com/embed/7goQ36ugF9w" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></li>
        <li><iframe width="560" height="315" src="https://www.youtube.com/embed/ZALaWPZtHjM" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></li>
        <li><iframe width="560" height="315" src="https://www.youtube.com/embed/9FIaKogLpo0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></li>
        <li><iframe width="560" height="315" src="https://www.youtube.com/embed/yBvqvinBAOE" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></li>
        <li><iframe width="560" height="315" src="https://www.youtube.com/embed/KYRH1JNz5_I" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></li>
        <li><iframe width="560" height="315" src="https://www.youtube.com/embed/oFKEXM7yuxM" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></li>

    </ul>
    </div>
</section>