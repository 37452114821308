<app-hero-image imgsrc="https://thedealiomarketing.com/wp-content/uploads/sites/2/2020/09/stretching-with-signal.jpg" darkbg="true" objectposition="top center">
	<div class="col-6"><h1>FAQ</h1>
	<div class="subtitle">Learn how this remarkable product can allow you to live with less pain.</div>
</div>
</app-hero-image>


<section class="" >
	<div class="featured-text">
		<h2>General Questions</h2>
		<p class="subtitle">Just Learning about Signal Relief? These may help</p>
	</div>
<div class="container">
	<div class="col-6">
		<h5>What is the difference between the 4.5" and the 3.5" patches?</h5>
<p>There are 2 different sizes available for comfort and convenience. Both patches will do the job, but sometimes the smaller patch may be more comfortable to wear in certain areas of the body.</p>

<h5>How does Signal work?</h5>
<p>By embedding billions of nano capacitors into a flexible circuit, signal is able to passively interact with the neural network to alleviate all kinds of pain from headaches, backaches, gout, and everything in-between!</p>

<h5>Does the Signal Relief Patch wear out?</h5>
<p>The signal relief patch is built to last! If treated properly your patch can last years, making it one of the most affordable options out there.</p>
	</div>
	<div class="col-6">
		<img src="https://thedealiomarketing.com/wp-content/uploads/sites/2/2020/08/running-signal.jpg" style="max-height: 500px;">
	</div>
</div>
</section>

<section class="padded-row  emphasized-row">
	<div class="featured-text">
		<h2>How to Use</h2>
		<p class="subtitle">If you recieved a patch and have questions about how best to use it</p>
	</div>
	<div class="container">
		<div class="col-6" style="padding: 40px;">
			<img src="https://jimkarol.com/wp-content/uploads/2020/06/signal-relief-product-packaging.jpg" class="rounded-image">
		</div>
		<div class="col-6">
			<h5>Does Signal help with back pain? </h5>
	<p>Of course! Signal works with all types of back pain including strains, sprains, bruises, disc pain, etc.</p>
<h5>What if the Patch doesn't work for me?</h5>
<p>Our technology has already helped thousands of individuals get the relief that they have been searching for. If you feel like the signal relief patch isn’t working for you, we offer a no-hassle 30 day money back guarantee. Before returning your signal relief patch we recommend that you reach out to us and let us help.</p>
<h5>What happens when it gets wet?</h5>
<p>The patch is very durable and is water resistant. Dry the patch off and it should be good to go back to work for you.</p>
<h5>Where do I place the patch?</h5>
<p>It is best to place the Signal Relief patch directly over the pain to start and make small adjustments to the left, right, or up toward the brain until you reach optimal relief.</p>
<h5>HOW DO I USE IT? HOW DO I APPLY IT?</h5>
<p>The patches do not need to be placed directly on the surface of the skin; they can be separated by a thin piece of clothing or cloth. Signal Pain Relief can be held in place using a variety of options such as the included double-sided tape strips, medical or athletic tape, safety pins, hook and loop, other tapes and wraps, or simply placed over the desired area or laid upon while resting or sleeping. </p>
</div>

</div>
</section>


<section class="padded-row">
	<div class="featured-text">
		<h2>Maintaining Your Patch</h2>
		<p class="subtitle">Get the most out of your Signal Relief patch everytime you wear it.</p>
	</div>
	<div class="container">

		<div class="col-6">
	
			<h5>How many times can I reuse them? How long do they last?</h5>
			<p>The patches are reusable, flexible, and durable therefore will last a long time (Months, Years), if treated with care.</p>
			<h5>How do I clean them?</h5>
			<p>The patches should be cleaned by hand with a damp cloth moistened with a mild detergent and then air dried. Never put the patch through a washing machine.</p>
			<h5>Will this help speed my recovery when I've overdone it during a workout or playing sports?</h5>
			<p>Post-exercise muscle soreness and injuries are familiar to every sports enthusiast, The Signal Relief patch promotes physical activity for users with age-related, mild joint stiffness and exercise-induced pain, which may help improve muscle strength and endurance.</p>
			
		</div>

		<div class="col-6">
			<img src="https://s3.amazonaws.com/DealioClients/Caliber/patches-1.png" class="responsive-img">
		</div>
		

</div>
</section>