<app-hero-image imgsrc="https://thedealiomarketing.com/wp-content/uploads/sites/2/2020/09/SignalStillsProofs-69.jpg" darkbg="true">
<h1>Testimonials</h1>
<p class="subtitle">What do people think of Signal Relief?</p>

</app-hero-image>

<section class="container featured-text">
	
		<p class="subtitle">This patch has changed countless people's lives by allowing them to live without pain. What can it do for you?</p>

</section>

<section class="emphasized-row padded-row">
	<div class="container">
		<h2 style="text-align: center; width: 100%;">What people are saying about Signal Relief</h2>

		<ul class="support-tiles">
			<li><iframe width="560" height="315" src="https://www.youtube.com/embed/_QOb0iyji4E" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></li>
			<li><iframe width="560" height="315" src="https://www.youtube.com/embed/OjmrfDrAs5Q" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></li>
			<li><iframe width="560" height="315" src="https://www.youtube.com/embed/4EpSafQls6Q" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></li>
			<li><iframe width="560" height="315" src="https://www.youtube.com/embed/s83wNJbEvG4" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></li>
			<li><iframe width="560" height="315" src="https://www.youtube.com/embed/YguXoXKG1bg" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></li>
			<li><iframe width="560" height="315" src="https://www.youtube.com/embed/7ysETb1ytvI" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></li>
		</ul>
		<h2 style="text-align: center; width: 100%;">Verified Customer Reviews</h2>
		<app-reviews></app-reviews>
	</div>
</section>



<section>
	<div class="featured-text">
		<h2>Customer Reviews</h2>
		
	</div>
<app-blog-tiles limit="3"></app-blog-tiles>
</section>