import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { HeroImageComponent } from './components/parts/hero-image/hero-image.component';
import { ModalComponent } from './components/parts/modal/modal.component';

import { ModalService } from './services/modal.service';
import { ShopComponent } from './components/pages/shop/shop.component';
import { HowItWorksComponent } from './components/pages/how-it-works/how-it-works.component';
import { TestimonialsComponent } from './components/pages/testimonials/testimonials.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { SupportComponent } from './components/pages/support/support.component';
import { AccountComponent } from './components/pages/account/account.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { BlogTilesComponent } from './components/parts/blog-tiles/blog-tiles.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { FooterctaComponent } from './components/parts/footercta/footercta.component';
import { PageheaderComponent } from './components/parts/pageheader/pageheader.component';
import { ProducttilesComponent } from './components/parts/producttiles/producttiles.component';
import { ReviewsComponent } from './components/parts/reviews/reviews.component';
 
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    HeroImageComponent,
    ModalComponent,
    ShopComponent,
    HowItWorksComponent,
    TestimonialsComponent,
    FaqComponent,
    SupportComponent,
    AccountComponent,
    ContactComponent,
    BlogTilesComponent,
    BlogComponent,
    FooterctaComponent,
    PageheaderComponent,
    ProducttilesComponent,
    ReviewsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [ModalService],
  bootstrap: [AppComponent]
})
export class AppModule { }
